
html,
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

#root{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 3px;
    padding: 0 !important;
}

.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.navbar-nav li a{
    font-size: 1rem;
    color:white !important;
    text-decoration: underline;
}

.full-screen-size{
    position: absolute;
    top: -1px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow: hidden;
    z-index: 10000;
}

#resultsTable{
   overflow: hidden;
}

#resultsTable .k-grid-table{
    border-bottom: 1px solid #ececec;
}
#resultsTable .k-grid td, #resultsTable .k-grid td a {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
#resultsTable .k-master-row:hover td{
    cursor: pointer !important;  
 } 

 #resultsTable tr.k-grid-norecords {
    min-width: 100%;
    text-align: center;
}
#resultsTable tr.k-grid-norecords td {
    min-width: 100%;
    position: absolute;
}

#resultsTable .k-pager-wrap.k-grid-pager.k-widget{
    padding: 2px !important;
}
#expand-collapse-table-btn:hover{
    cursor: pointer;
}
#expand-collapse-table-btn .k-icon{
    width: 0px;
    padding-top:10px;
}

#footer{
    height: 40px;
    line-height: 0.6;
    font-size: 12px;
}
#footer a {
    color: #337ab7;
}
#footer-hr{
    padding: 0px;
    margin: 5px;
}

/* DETAILS PAGE STYLES */

.details-table-header{
    background-color: #009dd0;
    color: white;
}

.clickable-span{
    cursor:pointer;
    color:#0080a5;
    text-decoration:underline;
}
.clickable-span:hover {
    text-decoration:none;
}

.person-link{
    cursor:pointer !important;
    color:#00799c !important;
    text-decoration:underline !important;
}
.person-link:hover{
    text-decoration:none !important;
}
.person-email-link{
    cursor:pointer !important;
    color:#00799c !important;
    text-decoration: none !important;
}

.personal-email-data{
    position: relative;
}
.personal-email-data .skype-link{
    width: 28px;
    height: 28px;
    background-image:url(../images/skype-64.png);
    background-size: 100%;
    background-color: white;
    position: relative;
    top: 4px;
    right: 5px;
    position: absolute;
    border-radius: 50%;
    border:1px solid #009dd0 ;
}
.personal-email-data .skype-link:hover{
    cursor: pointer;
    border-color: orange;
}
.main-table .skype-link{
    width: 22px;
    height: 22px;
}
.loader{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('../images/load.gif') 50% 50% no-repeat;
    background-color: white;
    opacity: 0.5;
  }

  .align-center{
    position:absolute;
    top:50%;
    text-align: center;
    width: 100%;
  }

  .request-fail-message-box{
      font-size: 18px;
      color: #555;
  }

  .main-table td{
      width: 60%;
      word-wrap: break-word;
  }
  .main-table th{
    width: 40%;
    word-wrap: break-word;
}
.header-table td, .header-table th{
    word-wrap: break-word;
}

.chat-btn {
    display:inline-block;
    width: 18px;
    height: 12px;
    position: absolute;
    right: 5px;
    border: 1px solid gray;
    border-radius: 2px;
  }
.chat-btn:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      left: 2px;
      bottom: -5px;
      border-left: 1px solid transparent;
      border-right: 12px solid transparent;
      border-top: 5px solid gray;
  }
  .chat-btn:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    left: 4px;
    bottom: -5px;
    border: 3px solid;
    border-color: #fff transparent transparent #fff;
}

.subordinates-table tbody {
    display:block;
    max-height:320px;
    overflow-x:auto;
}
.subordinates-table thead, .subordinates-table tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}


.subordinates-table .full-name{
    width: 41%;
}

.subordinates-table .phone{
    width: 70px;
    max-width: 70px;
}

.subordinates-table .email{
    width: 23px;
    max-width: 23px;
}

.subordinates-table-header{
    padding: 5px;
    color:white;
    text-align: center;
    font-weight: bold;
    border-right: 1px solid #ddd;
    border-left:  1px solid #ddd;
    border-bottom: 2px solid #ddd
}

thead.scroll-padding  {
    width: calc( 100% - 1.2em )
}

#presence{
    display: inline-block;
    min-height: 15px;
    min-width: 15px;
    background-color: #6eb005;
    border-radius: 50%;
    border: 2px solid #333;
}
#detailsBody td, th{
    word-wrap: break-word;
}

.presenceIcon{
    display: inline-block;
    font-size: 16px;
    margin-left: 3px;
    margin-bottom: -3px;
}

.presenceIcon.available{
    color: #6eb005;
}
.presenceIcon.busy{
    background-color: #e80620;
    min-width: 16px;
    min-height: 16px;
    border-radius: 50%;
}
.presenceIcon.offline{
    min-width: 16px;
    min-height: 16px;
    border: 2px solid #d6e3e8;
    border-radius: 50%;
}
.presenceIcon.dont-disturb{
    background-color: #e80620;
    min-width: 16px;
    min-height: 16px;
    border-radius: 50%;
    color:white;
}
.presenceIcon.away{
    color: #e9b471;
}
.presenceIcon.unknown{
    color: #d6e3e8;
}
.presenceIcon.out-of-office{
    font-size: 13px;
    min-width: 16px;
    min-height: 16px;
    border-radius: 50%;
    color: #b4009e;
    border: 2px solid  #b4009e;;
}

#details-page-body td, #details-page-body th{
    padding: 5px;
    font-size: 14px;
}

#details-page-body a{
    color: #0080a5;
}
#details-page-body{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
}
#details-page-body> [class*='col-'] {    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
/* END DETAILS PAGE STYLES */

/* ADVANCED SEARCH FILTERS */
input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}

input[type=search]::-ms-clear { display: inline-block; } 

label.k-label{
    color:#999;
    opacity:1;
    margin-top: 5px;
    margin-left: 15px;
    padding: 0px 5px;
    background-color: white; 
}

.k-textbox-container.k-state-empty.k-state-focused>.k-label{
    margin-top: 6px;
    margin-left: 15px;
    padding: 0px 5px;
}

.k-textbox-container.k-state-empty>.k-label {
    margin-top: 0px;
    margin-left: 0px;
    padding: 0px;   
}
.k-popup .k-list-scroller{
    max-height: 60vh !important;
}
.k-autocomplete .k-i-loading, .k-multiselect-wrap .k-i-loading{
    right: 22px;
}
span.k-icon.k-clear-value.k-i-close{
    right: 22px;
}

.cost-center-filter.k-autocomplete .k-i-loading, .cost-center-filter .k-multiselect-wrap .k-i-loading{
    right: 54px;
}
.cost-center-filter span.k-icon.k-clear-value.k-i-close{
    right: 54px;
}


/* ADVANCED SEARCH FILTERS END */

/* COST CENTER FILTER */
#btn-tree-wrapper{
    background-color: rgba(255, 166, 0, 0);
    color: grey;
    width: 30px; 
    height: 15px;
    max-height: 15px;
    padding: 0px;
    border-color: #ccc;   
    font-size: 9px;
}
#btn-tree-wrapper:hover{
   background-color: #CCC;
}
#cost-center-tree{
    background-color: white;
    position: fixed;
    width: 538px;
    height: 536px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    border: 1px solid grey;
}

#cost-center-tree-header{
    height: 20px;
    width: 100%;
    background-color: #09a3d5;
    top: 0px;
}

#cost-center-tree-header span{
    color: white;
    padding-left: 5px;
}

#cost-center-tree-header button{
    color: white;
    margin-right: 5px;
}

#cost-center-tree-header button > span{
    color: black;
}

#cost-center-tree-footer{
    position: absolute;
    bottom: 0px;
    height: 68px;
    width: 100%;
    background-color: #f0f0f0;
    padding-left: 5px;
}

#cost-center-tree-footer label{
    padding-top: 5px;
}
#cost-center-tree-body{
    width: 100%;
    height: 402px;
    margin-bottom: 30px;
    overflow-y: scroll;
}
#btn-tree-wrapper:hover{
    color: goldenrod;
    cursor: pointer;
}

.opaque-background{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: white;
    opacity: 0.7;
}

#btn-tree{
    width: 30px;
    height: 30px;
    min-width: 20px;
    text-align: center;
    padding: 5px;
    border-radius: 0%;
    border-color: rgba(0,0,0,0.08);
    background-color: white;
}
#btn-tree:hover{
    background-color: goldenrod;
    cursor: pointer;
}
#tree-view-search-wrapper{
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    border-bottom: 1px solid grey;
    padding-bottom: 5px;
    background-color: #f0f0f0;
    margin-bottom: 0px;
}
.cc-icon{
    display:block;
    width: 12px;
    height: 14px;
    border-radius: 100%;
    background-image:url(../images/cc-icon.png);
    background-size:100%;
    background-repeat: no-repeat;
    margin-right: 2px;
}

.afe-icon{
    display:block;
    width: 12px;
    height: 14px;
    border-radius: 100%;
    background-image:url(../images/afe-icon.png);
    background-size:100%;
    background-repeat: no-repeat;
    margin-right: 2px;
}

.img-thumbnail {
    display: block;
    background-image: url(../images/default-image.png);
    background-size: 100%;
    background-repeat: no-repeat;
}

.warning-icon{
    display:block;
    width: 12px;
    height: 14px;
    border-radius: 100%;
    background-image:url(../images/warning-icon.png);
    background-size:100%;
    background-repeat: no-repeat;
    margin-right: 2px;
}
.k-treeview .k-in{
    padding: 0px;
}
.k-treeview-lines{
    padding-left: 3px;
}
#cost-center-tree-footer label.checkbox-inline{
    color: #333;
}
.column-selecter .k-animation-container {
    z-index: 10000;
}
.k-animation-container.k-animation-container-relative{
    z-index: 99999999;
}
.k-grid-columnmenu-popup {
    width: fit-content;
}

.flex-container{
    display: flex !important;
    flex-direction: column;
    flex:1;
    position: relative;
    overflow: hidden;
}
.k-pager-wrap.k-grid-pager.k-widget{
    padding: 2px;
}

#excel-icon{
        font-size: 31px;
        color: green;
        margin-left: 5px;
}
#excel-icon:hover{
    cursor: pointer;
    color: #45b045;
}

/* CONST CENTER FILTER END */
.popover{
    max-width: unset;
}
.popover.bs-popover-right.show,
.popover .show .bs-popover-left{
    max-width: initial !important;
    z-index: 100000000;
    box-shadow: 0 8px 26px rgba(0, 0, 0, 0.4);
}
.popover-body{
    min-height: 138px;
    min-width: 300px;
    width:auto;
    display: flex;
    flex-direction: row;
    background-color: white;
}

.person-detail-labels p,
.person-details p
{
    white-space: nowrap;
}

.person-image{
    flex:1;
    max-width: 97px;
    min-height: 100%;
}
.person-detail-labels{
    flex:1;
    margin-left: 15px;
}
.person-details{
    flex:2;
    margin-left: 5px;
}
#person-detail-loading{
    position: relative;
    flex:2;
}

div#links {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1;
    flex: 1 1;
    justify-content: center;
    
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
}
.contact-icon{
    margin-top: -2px;
}

@media(max-width:767px) {
    img.navbar-brand-img {
        display: none;
    }
} 

span.align-right{
    display: inline-block;
    position: absolute;
    right: 10px;
}
span.align-right:hover{
    color: #09a3d5;
}

.popup-content{
    position: inherit;
    top: 7px;
    right: 15%;
    min-width: 440px;
}

#resultsTable .k-i-more-vertical::before, .k-i-vbars::before {
    content: '\E13A';
    font-size: 19px;
}

#resultsTable  span.k-icon.k-i-more-vertical {
    padding-left: 10px;
    padding-right: 29px;
    margin-top: -5px;
}

#resultsTable .k-i-more-vertical:hover{
    color: #09a3d5;
}

#advancedSearchForm .k-checkbox-label{
    color: #999;
    font-size: 14px;
}

.basic-multi-select {
    flex-grow: 1;
}

.kendo-select {
    padding-top: 0 !important;
    flex-grow: 1;
}

.cost-center-filter {
    flex-grow: 1;
}

.kendo-autocomplete {
    padding-top: 0 !important;
    flex-grow: 1;
}

.select__control {
    min-height: 0;
}

.input-group {
    flex-wrap: nowrap;
}